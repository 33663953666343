













































































import Vue, { PropType } from 'vue';
import combinationTable from '../components/combinationTable.vue';
import costCombination from '../costLibrary/combination/index.vue';
import { QyStlBillDeType, TableActionType } from '@/interface/costQuotaLibrary';
import { qyStlBillDeSave } from '@/api/costQuotaLibrary/costLibrary';
import type { QyStlBillDeParamsType } from '@/api/costQuotaLibrary/costLibrary';
import { updateQdAgainMath } from '@/api/costQuotaLibrary/detail';
import { mapGetters } from 'vuex';
import { costBeforeHandler } from '../index';
import QuotaMaterialDetail from '../components/quotaDetail.vue';
interface CurrentRecordType {
  index?: number;
  parentIndex?: number;
  record?: QyStlBillDeType;
}
const actionList = [
  {
    type: TableActionType.COST,
    name: '定额套用',
  },
];
export default Vue.extend({
  name: 'addInventoryProject',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array as PropType<QyStlBillDeType[]>,
    },
  },
  components: {
    combinationTable,
    costCombination,
    QuotaMaterialDetail,
  },
  data() {
    return {
      detailedListBind: {
        actionList,
        tableData: this.tableData,
        showPagination: false,
        actionWidth: '170px',
      },
      screenValue: 1,
      costVisible: false, // 成本组价弹框是否展示
      currentActionRecord: null as QyStlBillDeType | null, // 当前操作数据,
      quotaFiledSlot: ['bdCode', 'bdName'],
      currentRecordInfo: {
        record: {},
      } as CurrentRecordType,
      quotaRef: null as any,
    };
  },
  watch: {
    tableData: function (val) {
      if (val) this.detailedListBind.tableData = val;
    },
  },
  computed: {
    ...mapGetters(['currentMajorInfo', 'userInfo']),
    quotaEditField() {
      const quotaRef = this.quotaRef;
      if (quotaRef && quotaRef.isDataChange) return [];
      return ['quantity'];
    },
  },
  methods: {
    materialApply() {
      // 应用回调
      const { index, record, parentIndex } = this.currentRecordInfo;
      this.detailedListBind.tableData[parentIndex!].deList?.splice(
        index!,
        1,
        record!
      );
      this.againCalc(0);
    },
    viewRCJList(record, index, parentIndex) {
      record.adsQycbRcjModelList = (record.adsQycbRcjModelList || []).map(
        (item) => {
          item.sequenceNbr = item.id + new Date().getTime().toString(6);
          return item;
        }
      );
      this.$set(this.currentRecordInfo, 'record', record);
      this.$set(this.currentRecordInfo, 'index', index);
      this.$set(this.currentRecordInfo, 'parentIndex', parentIndex);
      this.$nextTick(() => {
        this.quotaRef = this.$refs.quotaDetail;
      });
    },
    againCalc(qdIndex = -1, deIndex = -1) {
      let params: QyStlBillDeParamsType = {
        qyStlBillDeList: this.detailedListBind.tableData,
      };
      if (deIndex >= 0) params.deIndex = deIndex;
      if (qdIndex >= 0) params.qdIndex = qdIndex;
      updateQdAgainMath(params).then(({ status, result }) => {
        if (status === 200 && result) {
          this.detailedListBind.tableData = [result];
          const { index, parentIndex, record } = this.currentRecordInfo;
          if (!parentIndex && parentIndex !== 0) return;
          this.$set(this.currentRecordInfo, 'record', result?.deList![index!]);
        }
      });
    },
    handleCancel() {
      this.$emit('update:visible', false);
      this.currentRecordInfo = {
        record: undefined,
      };
    },
    save() {
      const list = this.tableData;
      const deList = list[0].deList;
      if (list[0]?.projectAttr === '') {
        this.$message.warning('项目特征不能为空');
        return;
      }
      if (!deList || !deList.length) {
        this.$message.warning('定额不能为空');
        return;
      }
      if (this.isMaterialChange()) return;
      for (let k = 0; k < deList.length; k++) {
        const deItem = deList[k];
        if (deItem.isError) {
          this.$message.warning('请检查数据是否正确');
          return;
        }
      }
      qyStlBillDeSave({
        majornbr:
          this.currentMajorInfo?.sequenceNbr || this.tableData[0].majorNbr,
        xiliOpenId: this.userInfo?.openId,
        qyStlBillDeList: this.detailedListBind.tableData,
        agencyCode: this.userInfo?.agencyCode,
      }).then(({ status, result }) => {
        if (status === 200 && result) {
          this.handleCancel();
          this.$emit('refreshList');
          return this.$message.success('保存成功');
        }
        this.$message.error('保存失败');
      });
    },
    actionHandler({ record, key, index }) {
      this.actionHandlerByType({ record, key, index });
    },
    editHandler({ record, key, index }) {
      this.tableData.splice(index, 1, record);
    },
    quotaActionHandler({ record, key, index, parentIndex, parentRecord }) {
      if (key === TableActionType.DELETE) {
        this.tableData[parentIndex]?.deList?.splice(index, 1);
      }
    },
    quotaEditHandler({ record, key, index, parentIndex, parentRecord }) {
      const deList =
        (this.tableData[parentIndex] as QyStlBillDeType).deList || [];
      deList.splice(index, 1, record);
      this.againCalc(0, index);
    },
    actionHandlerByType({ record, key, index }) {
      if (this.isMaterialChange()) return;
      const map = {
        [TableActionType.COST]: this.costHandler,
      };
      return map[key]({ record, index });
    },

    /**
     * 定额材料是否有修改，未应用
     */
    isMaterialChange() {
      const quotaRef = this.quotaRef;
      if (quotaRef && quotaRef.applyTipHandler()) {
        // 数值有变动为保存应用
        this.$message.warning('数据有修改请点击应用');
        return true;
      }
      return false;
    },
    costHandler({ record, index }) {
      costBeforeHandler(this, record, index);
    },
    saveCallback(list) {
      if (!this.currentActionRecord) return;
      const customIndex = this.currentActionRecord?.customIndex;
      if (customIndex || customIndex === 0) {
        let parentData = this.tableData[customIndex];
        parentData.deList = list;
        this.tableData.splice(customIndex, 1, parentData);
        this.$set(this.detailedListBind, 'tableData', this.tableData);
        this.againCalc(0);
      }
    },
  },
});
