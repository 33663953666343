var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('customDrawer',{staticClass:"common-dialog",attrs:{"placement":"right","width":"80%","visible":_vm.visible,"closable":false},on:{"close":_vm.handleCancel}},[_c('template',{slot:"title"},[_c('div',{staticClass:"drawer-title"},[_c('a-icon',{staticClass:"icon",attrs:{"type":"left"},on:{"click":_vm.handleCancel}}),_vm._v(" 新增组价方案 ")],1)]),_c('div',{staticClass:"detail-add-box"},[_c('combinationTable',{staticClass:"combination-table scrollbar",attrs:{"detailedListBind":_vm.detailedListBind,"detailedListFunction":{ actionHandler: _vm.actionHandler, editHandler: _vm.editHandler },"quotaFunction":{
        actionHandler: _vm.quotaActionHandler,
        editHandler: _vm.quotaEditHandler,
      },"quotaBind":{
        fieldSlots: _vm.quotaFiledSlot,
        editFields: _vm.quotaEditField,
      }},scopedSlots:_vm._u([_vm._l((_vm.quotaFiledSlot),function(item,key){return {key:item,fn:function(ref){
      var record = ref.record;
      var text = ref.text;
      var index = ref.index;
      var parentIndex = ref.parentIndex;
return [_c('div',{key:key,staticClass:"link",on:{"click":function($event){return _vm.viewRCJList(record, index, parentIndex)}}},[_vm._v(" "+_vm._s(text)+" ")])]}}})],null,true)}),_c('div',{staticClass:"material-content"},[_c('quota-material-detail',{ref:"quotaDetail",attrs:{"adsQycbRcjModelList":_vm.currentRecordInfo.record &&
          _vm.currentRecordInfo.record.adsQycbRcjModelList,"qyDePriceConstituteList":_vm.currentRecordInfo.record &&
          _vm.currentRecordInfo.record.qyDePriceConstituteList},on:{"apply":_vm.materialApply}})],1)],1),_c('div',{staticClass:"btn-group"},[_c('a-button',{on:{"click":_vm.handleCancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v(" 保存 ")])],1),_c('cost-combination',{attrs:{"visible":_vm.costVisible,"actionRecord":_vm.currentActionRecord,"isDetailAdd":true},on:{"update:visible":function($event){_vm.costVisible=$event},"saveCallback":_vm.saveCallback}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }